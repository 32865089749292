
/* BOOKING */
.booking-section {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
}

.booking-img {
  width: 50%;
  height: 700px;
  object-fit: cover;
}

.booking-content {
  width: 50%;
  text-align: left;
  padding: 50px;
}

.booking-pre-head {
  width: 250px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .booking-section {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .booking-img {
    height: 500px;
  }

  .booking-pre-head {
    width: 150px;
  }

  .booking-content {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 600px) {
  .booking-section {
    margin-bottom: 40px;
    flex-direction: column;
  }

  .booking-img {
    width: 100%;
    height: 300px;
  }

  .booking-content {
    width: 100%;
    padding: 25px 15px;
  }
}


@media screen and (max-width: 500px) {
  .booking-img {
    height: 250px;
  }
}