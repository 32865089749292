
.gallery-slider {
  padding-top: 40px;
  padding-bottom: 40px;
}

.gallery-slider-img-cont {
  position: relative;
  height: 650px;
}

.gallery-slider-img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  opacity: 0.01;
  transition: opacity 0.5s;
}

  .gallery-slider-img.active {
    z-index: 1;
    opacity: 1;
  }

.gallery-slider-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

  .gallery-slider-nav p {
    margin: 0 20px;
  }

.gallery-slider-pre {
  margin: 0 auto 20px auto;
  line-height: 1.5em;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .gallery-slider-img-cont {
    height: 500px;
  }
}


@media screen and (max-width: 1000px) {
  .gallery-slider-img-cont {
    height: 400px;
  }

  .gallery-slider {
    padding-top: 60px;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 500px) {
  .gallery-slider-img-cont {
    height: 350px;
  }
}