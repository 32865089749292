
.section-404 {
  margin-top: 260px;
  margin-bottom: 180px;
}

.img-404 {
  width: 200px;
  margin-bottom: 20px;
}

.text-404 {
  max-width: 500px;
  margin: 20px auto;
}


/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .section-404 {
    margin-top: 100px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 700px) {
  .img-404 {
    width: 100px;
    margin-bottom: 0;
  }
}