
/* Showcase */
.home-showcase-section {
  padding: 50px 0;
}

.home-showcase {
  display: flex;
  flex-direction: row;
}

.home-showcase-content {
  background-color: #fff;
  padding: 20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .home-showcase-content.gold {
    background-color: white;
  }

.home-showcase-text {
  max-width: 400px;
}

.home-showcase-img {
  width: 40%;
  height: 800px;
  object-fit: cover;
}

.home-showcase-img.show-when-sm {
  display: none;
}

.section-head {
  font-size: 4em;
  margin: 0 0 30px 0;
  color: #ab9355;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .home-showcase-img {
    height: 600px;
  }
}

@media screen and (max-width: 1000px) {
  .home-showcase-img {
    height: 450px;
  }

  .home-showcase-section {
    padding: 40px 0;
  }

  .home-showcase-content {
    padding: 15px;
  }

  .home-showcase-text {
    margin-top: 0;
  }
}


@media screen and (max-width: 700px) {
  .home-showcase-section {
    padding: 30px 0;
  }

  .home-showcase {
    flex-direction: column;
  }

  .home-showcase-img {
    width: 100%;
    height: 300px;
  }

  .home-showcase-content {
    width: 100%;
    padding: 25px 15px;
  }

  .home-showcase-img.show-when-sm {
    display: block;
  }

  .home-showcase-img.hide-when-sm {
    display: none;
  }
}