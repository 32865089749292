
.page-gallery-section {
  padding-top: 100px;
  padding-bottom: 40px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .page-gallery-section {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}