
.menu-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background-color: #f8f7f4;
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

  .menu-top.scrolled {
    height: 70px;
    background-color: #fff;
    box-shadow: 2px 3px rgba(90, 90, 90, 0.1);
  }

.menu-top-inner {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.menu-top-logo {
  display: block;
  height: 70px;
  padding-left: 0;
  transition: height 0.5s ease-in-out;
}

  .menu-top.scrolled .menu-top-logo {
    height: 55px;
  }

.menu-top-links {
  width: calc(80% - 100px);
  text-align: left;
}

  .menu-top-links:last-of-type {
    text-align: right;
  }

.mobile-menu-toggle {
  display: none;
}

.drawer-inner {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 50px 0 10px 0;
  background-color: #B7A371;
  color: #fff;
  position: relative;
  height: 100%;
}

.drawer-menu-logo {
  width: 200px;
  margin: auto auto 40px auto;
}


/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .menu-top-links {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .menu-top {
    height: 70px;
  }

    .menu-top.scrolled {
      height: 60px;
    }

  .menu-top-logo {
    height: 60px;
  }

    .menu-top.scrolled .menu-top-logo {
      height: 40px;
    }
}


@media screen and (max-width: 500px) {
  .menu-top-logo {
    height: 50px;
  }
}