
/* HEADER */
.home-header {
  position: absolute;
  margin-top: -600px;
  margin-left: 100px;
  text-align: left;
}

  .home-header img {
    width: 200px;
  }

  .home-header h1 {
    font-size: 5em;
    margin: 10px 0;
    color: white;
  }

  .home-header p {
    font-size: 1.2em;
    font-style: italic;
    color: white;
  }

/* BANNER */
.banner-spinner {
  width: 100%;
  height: 820px;
  position: relative;
  margin-top: 100px;
}

  .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.01;
    transition: opacity 0.5s;
  }
  
    .banner-img.active {
      z-index: 1;
      opacity: 1;
    }

/* STARS */
.home-stars {
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.home-stars img {
  width: 500px;
}

/* COLLAGE */
.home-collage img {
  width: 100%;
}

/* INFO SECTIONS */
.home-info-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 100px;
}

.home-info-img {
  width: 50%;
  position: relative;
}

  .home-info-img img {
    object-fit: cover;
    height: 650px;
    width: 100%;
  }

  .home-info-img img.home-info-img-sub {
    position: absolute;
    top: calc(100% - 230px);
    right: calc(100% - 280px);
    width: 350px;
    height: 300px;
    border: 8px solid white;
  }

.home-info-content {
  width: 50%;
  text-align: left;
  padding-left: 80px;
}

  .home-info-content.alternate {
    padding-left: 0;
    padding-right: 80px;
  }

.home-info-content h2 {
  font-size: 3em;
  margin: 0 0 30px 0;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .home-header {
    padding-top: 160px;
  }

  .home-header img {
    width: 60px;
  }

  .home-header h1 {
    font-size: 3em;
  }

  .banner-spinner {
    height: 750px;
  }
}

@media screen and (max-width: 1000px) {
  .home-header {
    padding-top: 120px;
  }
  .banner-spinner {
    margin-top: 50px;
  }
}

@media screen and (max-width: 700px) {
  .banner-spinner {
    height: 350px;
  }

  .home-stars {
    padding: 60px 0;
  }

  .home-header {
    padding-top: 300px;
    padding-left: 0;
    margin-left: 0px;
  }

  .home-stars img {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .home-header h1 {
    font-size: 2.5em;
  }

  .banner-spinner {
    height: 300px;
  }

  .home-header {
    padding-top: 350px;
    padding-left: 0;
  }

  .home-stars img {
    width: 200px;
  }
}