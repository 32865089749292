
.gallery-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.venue-heading h1 {
  font-size: 4em;
  margin: 60px 0 0 0;
  color: #ab9355;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .gallery-section {
    padding-top: 0;
  }

  .venue-heading h1 {
    font-size: 3em;
  }
}

@media screen and (max-width: 700px) {

  .venue-heading h1 {
    font-size: 2em;
  }
}