
/* ABOUT SECTIONS */
.about-block-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 100px;
}

.about-block-img {
  width: 50%;
  position: relative;
}

  .about-block-img img {
    object-fit: cover;
    height: 650px;
    width: 100%;
  }

  .about-block-img img.about-block-img-sub {
    position: absolute;
    top: calc(100% - 230px);
    right: calc(100% - 280px);
    width: 350px;
    height: 300px;
    border: 8px solid white;
  }

  .about-block-img.show-when-sm {
    display: none;
  }

.about-block-content {
  width: 50%;
  text-align: justify;
  padding-left: 80px;
}

  .about-block-content.alternate {
    padding-left: 0;
    padding-right: 80px;
  }

.about-block-content h2 {
  font-size: 4em;
  margin: 0 0 30px 0;
  color: #ab9355;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1250px) {
  .about-block-content h2 {
    font-size: 2.5em;
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 1000px) {
  .about-block-section {
    margin-bottom: 80px;
  }

  .about-block-img img {
    height: 500px;
  }

  .about-block-content {
    padding-left: 50px;
  }
  
    .about-block-content.alternate {
      padding-right: 50px;
    }

  .about-block-img img.about-block-img-sub {
    position: absolute;
    top: calc(100% - 130px);
    right: calc(100% - 220px);
    width: 270px;
    height: 220px;
    border: 8px solid white;
  }
}

@media screen and (max-width: 700px) {
  .about-block-section {
    margin-bottom: 40px;
    flex-direction: column;
  }

  .about-block-img {
    width: 100%;
  }
  
  .about-block-content {
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-top: 20px;
  }

    .about-block-content.alternate {
      padding-right: 0;
    }

  .about-block-content h2 {
    margin: 0 0 15px 0;
  }

  .about-block-img.show-when-sm {
    display: block;
  }

  .about-block-img.hide-when-sm {
    display: none;
  }
}


@media screen and (max-width: 500px) {

  .about-block-img img {
    height: 350px;
  }

  .about-block-content h2 {
    font-size: 2em;
  }
}