/*
* General styling to be used across the whole site
*/

/* Reseting box-sizing */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Noto Sans";
  font-weight: 400;
  margin: 0;
  text-align: center;
  color: rgb(122, 116, 106);
  background-color: #f8f7f4;
}

#root {
  position: relative;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-family: "Allura";
}

.full-width {
  width: 100%;
}

.standard-box {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 1px -1px rgba(0, 0, 0, 0.22);
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.no-underline {
  text-decoration: none;
}

.relative {
  position: relative;
}

.standard-width {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.standard-width-half {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  width: 300px;
  margin: 50px 0;
}

  .divider.no-margin-top {
    margin-top: 0;
  }

.divider-div {
  height: 100px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.fade-exit {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.section-head {
  font-size: 3em;
  margin: 15px 0 30px 0;
}

.section-head-pre {
  margin: 0 0 10px 0;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1250px) {
  .standard-width {
    padding-right: 20px;
    padding-left: 20px;
  }

    .standard-box.standard-width {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 0;
      padding-right: 0;
      width: calc(100% - 40px);
    }
}

@media screen and (max-width: 1200px) {
  body {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 800px) {
  body {
    font-size: 0.8em;
  }

  .divider {
    width: 300px;
    margin: 30px 0;
  }
  
  .divider-div {
    height: 60px;
  }

  .section-head {
    font-size: 2.5em;
    margin: 10px 0 20px 0;
  }
}