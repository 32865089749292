
.info-blocks {
  padding-bottom: 50px;
}

.info-blocks h2 {
  font-size: 4em;
  margin: 0 0 30px 0;
  color: #ab9355;
}

.info-blocks-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-block {
  background-color: #fff;
  width: 32%;
  padding: 20px 20px;
  position: relative;
  overflow: hidden;
}

  .info-block.gold {
    background-color: #b2c7b9;
    color: #fff;
  }

  .info-block-img {
    width: 100%;
  }

  /* .info-block-img.alt-1 {
    position: absolute;
    top: 63%;
    left: -5%;
    width: 100%;
    max-width: 180px;
    transform-origin: top;
    transform: scale(1) rotate(15deg);
    opacity: 0.6;
  }

  .info-block-img.alt-2 {
    position: absolute;
    top: 70%;
    left: 30%;
    width: 100%;
    max-width: 180px;
    transform-origin: top;
    transform: scale(1) rotate(0deg);
    opacity: 0.6;
  }

  .info-block-img.alt-3 {
    position: absolute;
    top: 62%;
    left: 61%;
    width: 100%;
    max-width: 180px;
    transform-origin: top;
    transform: scale(1) rotate(-10deg);
    opacity: 0.6;
  } */

  .info-block h3 {
    font-size: 3em;
    margin: 0 0 30px 0;
  }

  .info-block p {
    margin-bottom: 30px;
  }

  .text-2-header {
    padding: 10px 2px;
    background-color: #ab9355a2;
    color: white;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .info-block {
    width: 31%;
    padding: 50px 10px;
  }
}

@media screen and (max-width: 700px) {
  .info-blocks {
    padding: 0;
  }

  .info-blocks-grid {
    flex-direction: column;
    align-items: center;
  }

  .info-block {
    width: 100%;
    margin-bottom: 20px;
  }
}