
footer {
  background-color: #b2c7b9;
}

.footer-socials {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px 20px 20px 20px;
  margin: auto;
}

.footer-social-icon {
  width: 120px;
  height: 120px;
  padding: 10px;
  object-fit: contain;
}

.footer-contact {
  color: white;
  width: 100%;
  margin: auto;
  padding: 15px;
  font-size: 1.2em;
  border: 1px solid rgb(122, 116, 106);
}

  .footer-contact a {
    color: white;
    text-decoration: none;
  }

.footer-logo {
  width: 100%;
  max-width: 350px;
  margin: 20px 0;
}

.footer-legal {
  color: rgb(122, 116, 106);
  margin: 0;
  padding: 10px 0;
  font-size: 0.8em;
  padding-bottom: 50px;
}

  .footer-legal a {
    color: rgb(122, 116, 106);
    text-decoration: underline;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .footer-social-icon {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 700px) {
  .footer-socials {
    padding: 20px 10px 10px 10px;
  }

  .footer-social-icon {
    width: 80px;
    height: 80px;
  }

  .footer-logo {
    max-width: 250px;
  }

  .footer-legal {
    padding-bottom: 20px;
  }
}