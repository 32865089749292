
/* TESTIMONIALS */
.home-testimonials-section {
  padding-top: 50px;
  padding-bottom: 80px;
}

.testimonials-pre-head {
  width: 80px;
  opacity: 1;
}

.home-testimonial {
  padding-left: 50%;
  padding-top: 50px;
  position: relative;
  margin-top: 50px;
}

.home-testimonial-content {
  background-color: #fff;
  padding: 50px 80px;
  min-height: 400px;
}

.home-testimonial-img-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 90%;
  object-fit: cover;
  z-index: -1;
}

.home-testimonial-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-testimonial-content h3 {
  font-size: 2em;
  margin: 15px 0;
}

.home-testimonial-content p {
  font-style: italic;
  line-height: 1.5em;
}

.home-testimonial-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

  .home-testimonial-nav p {
    margin: 0 20px;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .home-testimonials-section {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .testimonials-pre-head {
    width: 45px;
  }

  .home-testimonial-content {
    padding: 40px 60px;
    min-height: 300px;
  }
}


@media screen and (max-width: 700px) {
  .home-testimonial-img-cont {
    position: static;
    width: 100%;
    height: 300px;
  }

  .home-testimonial {
    padding-left: 0;
    padding-top: 0;
    margin-top: 30px;
  }

  .home-testimonial-content {
    padding: 10px 20px;
    min-height: auto;
  }

  .home-testimonial-content h3 {
    margin-top: 0;
  }
}


@media screen and (max-width: 500px) {
  .home-testimonial-img-cont {
    height: 250px;
  }
}