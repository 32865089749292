
/* ======= CONTACT ======= */
.contact-form-section {
  margin-top: 80px;
  margin-bottom: 80px;
}

  .contact-form-section h2 {
    font-size: 2.5em;
    margin: 0 0 30px 0;
  }

.contact-form {
  background-color: #fff;
  padding: 20px;
}

.contact-heading h1 {
  font-size: 4em;
  margin: 60px 0 0 0;
  color: #ab9355;
}

/* ======= DETAILS ======= */
.contact-details-section {
  margin-top: 100px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-details-section a {
  color: #7e786e;
}

.contact-details-section a:hover {
  color: #b2c7b9;
}

.map {
  width: 50%;
  height: 400px;
  border: none;
}

.contact-details {
  width: 50%;
  padding-right: 20px;
  text-align: left;
}

  .contact-details h2 {
    font-size: 2em;
    margin-bottom: 5px;
  }

    .contact-details h2:first-of-type {
      margin-top: 0;
    }

  .contact-details table {
    width: 100%;
  }

  .contact-details td {
    padding: 2px 0;
    border-bottom: 1px dotted #cc92a8;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1250px) {
  .contact-form-section.standard-box.standard-width {
    padding: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .contact-form-section {
    margin-top: 60px;
  }

  .contact-heading h1 {
    font-size: 3em;
  }
  

  .contact-form-section h2 {
    font-size: 1.8em;
    margin: 0 0 20px 0;
  }

  .contact-details-section {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .contact-details h2 {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 700px) {
  .contact-form-section {
    margin-top: 50px;
  }

  .contact-form-section.standard-box.standard-width {
    padding: 10px;
  }

  .contact-heading h1 {
    font-size: 2em;
  }

  .contact-form-section h2 {
    font-size: 1.5em;
  }

  .contact-details-section {
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
  }
  
  .map {
    width: 100%;
    height: 300px;
  }
  
  .contact-details {
    width: 100%;
    padding: 0 0 20px 0;
  }

  .contact-details h2 {
    font-size: 1.5em;
  }
}


@media screen and (max-width: 700px) {
  .map {
    height: 250px;
  }
}